.Testimonials-container
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Testimonials-container .testimonial-card
{
    cursor: pointer;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 12px #00000017;
    border-radius: 30px;
    padding: 20px;
    height: 100%;
}
.Testimonials-container .testimonial-card .arrow-img
{
    width:45px;
}


.Testimonials-container .testimonial-card .testimonial-card-info
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}


.Testimonials-container .profile-info
{
    
}


.Testimonials-container .testimonial-card-line-seprator
{
    border: 1px solid #c8c8c8;
    width: 100%;
    height: 0;
    
}
.Testimonials-container .profile-info .name
{
    color: #141414;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 10px;
}

.Testimonials-container .btn-container
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}


.Testimonials-container .swiper-pagination
{
}
.Testimonials-container .swiper-pagination .swiper-pagination-bullet
{
    background-color: #E1E1E1;
    opacity: 1;
}
.Testimonials-container .swiper-pagination .swiper-pagination-bullet-active
{
    background-color: #AD1E17;
    opacity: 1;
}

.Testimonials-container .swiper-slide 
{
    height: auto;
}

@media(max-width: 750px)
{
    .Testimonials-container .swiper-slide .testimonial-card
    {
        width: 95%;
        margin: auto;
    }
}
@media(max-width: 576px)
{
    .Testimonials-container .swiper-slide .testimonial-card
    {
        width: 100%;
        margin: auto;
    }
}