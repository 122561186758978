.Footer-container
{
    background-color: #313131;
}

.Footer-container .anchor
{
    color: #EAEBEB;
    text-decoration: none;
    transition: .3s;
}
.Footer-container .anchor:hover
{
    color: #888888;
}

.Footer-container .social-icons
{
    width: 40px;
    height: 40px;
}

.Footer-container .body-paragraph{
    color: #EAEBEB;
}

