.AdditionalResources-container
{

}

.AdditionalResources-container .main-box
{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 36px #00000029;
    border-radius: 26px;
    padding: 40px 30px;
}