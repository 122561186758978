.NewsLetter-container
{

}


.NewsLetter-container .form-container
{

}

.NewsLetter-container .form-container .inp-container
{
    background:transparent;
    border: 1px solid #707070;
    padding-left:5px;
    border-radius: 32px;
    width:65%;
    margin: auto;
    margin-top: 40px;
    overflow: hidden;
}
.NewsLetter-container .form-container .inp-container input{
    width:100%;
    border: none;
    padding: 10px;
    padding-left: 15px;
    color: #707070;
    border-radius: 5px;
    background:transparent;

}
.NewsLetter-container .form-container .inp-container input::placeholder{
    color: #707070;
}
.NewsLetter-container .form-container .inp-container input:focus{
    outline: none;
}
.NewsLetter-container .form-container .inp-container .genral-btn
{
    
    width: 200px;
    padding-top: 12px;
    padding-bottom: 12px;
}
.NewsLetter-container .form-container .inp-container-resp
{
    background:transparent;
    padding-left:5px;
    border: none;
    width:80%;
    margin: auto;
    margin-top: 40px;
    overflow: hidden;
}
.NewsLetter-container .form-container .inp-container-resp input{
    width:100%;
    border: 1px solid white;
    padding: 10px;
    padding-left: 15px;
    border-radius: 32px;
    color: white;
    background:transparent;
}
.NewsLetter-container .form-container .inp-container-resp input::placeholder{
    color: white;
}
.NewsLetter-container .form-container .inp-container-resp input:focus{
    outline: none;
}

@media(max-width:498px)
{
    .NewsLetter-container .form-container .inp-container input{
        width:53%;
    
    }
}