.blog-container
{
    background: transparent;
    box-sizing: border-box;
}
.blog-container .card-box-blog-blog
{
    display: flex;
    background-color: transparent;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: start;
    gap: 10px;
    height: 100%;
    cursor: pointer;
}

.blog-container .swiper-slide{
    height: auto;
}

.blog-container .card-box-blog-blog .card-text-content
{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin: auto;
}

.blog-container .card-box-blog .blog-info
{
    width: 90%;
}

.blog-container .card-box-blog .title{
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
    color: white;
}
.blog-container .card-box-blog .author{
    font-size: 16px;
    color: #e0e2e6;
    font-weight: 500;
}
.blog-container .card-box-blog .pb-date{
    color :#e0e2e6;
    font-size: .78rem;
    font-weight: 500;
}
.blog-container .card-box-blog .line-seprator{
    border: 1px solid grey;
    width: 90%;
    height: 0;
}

.blog-container .card-box-blog .admin-icon
{
    height: 14px;
    width: 15px;
    margin-bottom: 4px;
}
.blog-container .card-box-blog .date-icon
{
    height: 14px;
    width: 15px;
}

.blog-container .card-box-blog a
{
    text-decoration: none;
    font-size: 16px;
    color: #39b4e4;
    font-weight: 500;
    transition: .3s;
}


.blog-container .card-box-blog a:hover{
    color: #1e83ab;
    cursor: pointer;
}

.blog-container .card-box-blog img{
    width: 100%;
    margin-bottom: 10px;
}

.blog-container .card-box-blog .btn-icon
{
    width: 12px;
    height: 12px;
    margin-bottom: 0;
    margin-left: 3px;
}

.blog-container .card-box-blog .top-links
{
    display: flex;
    justify-content: start;
    gap: 15px;
}

.blog-container .card-box-blog .top-links img{
    width: 15px;
}
.blog-container .card-box-blog .top-links p{
    font-size: .8rem;

}

.blog-container .blog-text-content
{
}

.blog-container .blog-upper-content
{
}

.blog-container .blog-upper-content .blog-text-content
{
    
}
.blog-container .blog-btn
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.blog-container .blog-btn .swiper-button
{
    border-radius: 50%;
    border: none;
    background-color: white;
    border: 1px solid white;
    padding: 10px 20px;
    color: purple;
    box-shadow:0 3px 8px 0 RGBA(0,0,0,0.16 );
    transition: .2s;
}
.blog-container .blog-btn .swiper-button:hover
{
    border: 1px solid #8740A5;
    box-shadow:0 0px 0px 0 RGBA(0,0,0,0.16 );
}

.blog-container .card-list
{
    /* margin: auto;
    width: 90%; */
    height: fit-content;
}

.blog-container .btn-arrow-icon
{
    width: 14px;
    height: 14px;
}
.blog-container .btn-arrow-icon-left
{
    transform: rotate(130deg);
    transition:transform .3s;
}
.blog-container .btn-arrow-icon-left-rotate
{
    transition:transform .3s;
    transform: rotate(180deg);
}

.blog-container .card-box
{
    color: black;
    display: flex;
    padding: 20px;
    padding-bottom: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 21px #00000029;
    border-radius: 26px;
    /* height: 100%; */
    cursor: pointer;
}


.blog-container .card-box .card-text-content
{
    width: 93%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin: auto;
}

.blog-container .card-box h5{
    color :#373737;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
}
.blog-container .card-box h2{
    color :#373737;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
}
.blog-container .card-box p{
    color :#949494;
    font-size: .78rem;
}


.blog-container .card-box a
{
    text-decoration: none;
    font-size: .8rem;
    transition: .3s;

}


.blog-container .card-box a:hover{
    cursor: pointer;
    color: #E1A92A;
}

.blog-container .card-box img{
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
}

.blog-container .card-box .btn-icon
{
    width: 12px;
    height: 12px;
    margin-bottom: 0;
    margin-left: 3px;
}

.blog-container .card-box .top-links
{
    display: flex;
    justify-content: start;
    gap: 15px;
}

.blog-container .card-box .top-links img{
    width: 15px;
}
.blog-container .card-box .top-links p{
    font-size: .8rem;

}