.sponsor-container
{
    border-radius: 80px;
  margin-left: auto;
  margin-right: auto;
}

.sponsor-container .companies-logo-box .swiper-slide 
{
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
}
.sponsor-container .companies-logo-box .swiper-wrapper 
{
    height: auto;
}
 
/* .sponsor-container .companies-logo-box img{
    max-height: 130px;
}

.sponsor-container .companies-logo-box .swiper{

    max-height: 130px;
    
} */

@media(max-width:443px)
{
    .sponsor-container .companies-logo-box .hideOnNav{
    
       position: relative!important;
       z-index: -1!important;
    }
    
}