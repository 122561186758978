.CheckList-container
{

}


.CheckList-container .custom-bullet-list{
    list-style-image: url('../../../public/images/icons/hero-bullets.svg'); 
    padding-left: 20px; 
   
}