* {
  margin: 0; 
  padding: 0;
  scroll-behavior: smooth; 
  font-family: "Onest", sans-serif;
}

.color-red
{
  color: #E9511A!important;
}
.color-white
{
  color: white!important;
}


.standard-margin-space {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.standard-padding-space {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.body-heading, .hero-section-heading { 
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-family: "Onest", sans-serif;
}
.body-heading span, .hero-section-heading span{ 
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Onest", sans-serif;
}

.body-heading {
   color: #006EAE; 
   font-size: 43px; 
   font-weight: bold;
   font-family: "Onest", sans-serif;
  }

.hero-section-heading  
{ 
  font-size: 60px;
  font-weight: bold;
  color: white; 
  font-family: "Onest", sans-serif;
  /* letter-spacing: 4px;  */
}
.hero-section-heading span {font-family: "Onest", sans-serif;}
  
.body-paragraph { 
  font-weight: normal;
  font-size: 16px;
  color: #373737;
  line-height: 31px;
  font-family: "Onest", sans-serif;
}

  

.genral-btn{ 
  padding: 0.5rem 2rem;
  font-size: 14px;
  font-weight: 400;
  color: white;
  border-radius: 20px;
  filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.15));
  background: #E9511A 0% 0% no-repeat padding-box;
  border: none;
  transition: .3s;
  font-family: "Onest", sans-serif;
}
.genral-btn:hover{ 
  background: #ab3004 0% 0% no-repeat padding-box;
}

.hero-sub-heading
{
  font-size: 25px;
  font-weight: 600;
  color: white;
  font-family: "Onest", sans-serif;
}
.sub-heading
{
  font-size: 22px;
  font-weight: 600;
  color: #1F2F54;
  text-transform: capitalize;
  font-family: "Onest", sans-serif;

}

.genral-btn span {
  margin-right: .4rem;
}

@media (max-width: 1250px) {
  .body-heading  {
    font-size: 35px;
  }

  .hero-section-heading {
    font-size: 45px;
  }
  .body-paragraph { 
    line-height:normal;
  }
}

@media (max-width: 460px) {
  .body-heading  {
    font-size: 30px;
  }
 
  .hero-section-heading  {
    font-size: 35px;
  }

  .body-paragraph {
    font-size: 15px;
  }
}

@media (max-width: 380px) {
  .body-heading  {
    font-size: 27px;
  }

  .hero-section-heading  {
    font-size: 32px;
  }

  .body-paragraph {
    font-size: 14px;
  }

  .sub-heading {  
    /* font-size: 12px;  */
  } 
}


/* Width, color, and radius for the track */
::-webkit-scrollbar {
  width: 12px;
}

::-moz-scrollbar {
  width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #AD1E17;
  transition: .3s;
  border-radius: 3px;
}

::-moz-scrollbar-thumb {
  background: #AD1E17;
  transition: .3s;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #AD1E17;
}

::-moz-scrollbar-thumb:hover {
  background: #AD1E17;
}

/* Track */
::-webkit-scrollbar-track {
  transition: .3s;
  background: #eeb0ad;
}

::-moz-scrollbar-track {
  transition: .3s;
  background: #eeb0ad;
}
/* Track on hover */
::-webkit-scrollbar-track:hover {
  transition: .3s;
  background: #eeb0ad;
}

::-moz-scrollbar-track:hover {
  transition: .3s;
  background: #eeb0ad;
}

/* Handle when dragging */
::-webkit-scrollbar-thumb:active {
  background: #AD1E17;
}

::-moz-scrollbar-thumb:active {
  background: #AD1E17;
}

/* Track when dragging */
::-webkit-scrollbar-track:active {
  transition: .3s;
  background: #eeb0ad;
}

::-moz-scrollbar-track:active {
  transition: .3s;
  background: #eeb0ad;
}
