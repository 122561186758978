.ReasonedText-container
{

}

.ReasonedText-container .box
{
    background: #FFFFFF;
    box-shadow: 0px 3px 36px #00000029;
    border-radius: 26px;
    padding: 50px 30px;
}