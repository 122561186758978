.SimpleTextCard-container
{

}

.SimpleTextCard-container .simple-card-box
{
    padding: 40px 25px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 36px #00000029;
    border-radius: 26px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: .3s;
}
.SimpleTextCard-container .simple-card-box:hover
{
    transform: translateY(-15px);
    cursor: pointer;
}