.ImmigrationServices-container
{

}

.ImmigrationServices-container .ImmigrationServicesCard-container
{
    color: black;
    display: flex;
    padding: 20px;
    padding-bottom: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 21px #00000029;
    border-radius: 26px;
    height: 100%;
    cursor: pointer;
}


.ImmigrationServices-container .ImmigrationServicesCard-container .card-text-content
{
    width: 93%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin: auto;
}

.ImmigrationServices-container .ImmigrationServicesCard-container h5{
    color :#373737;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
}
.ImmigrationServices-container .ImmigrationServicesCard-container h2{
    color :#373737;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
}
.ImmigrationServices-container .ImmigrationServicesCard-container p{
    color :#949494;
    font-size: .78rem;
}


.ImmigrationServices-container .ImmigrationServicesCard-container a
{
    text-decoration: none;
    font-size: .8rem;
    transition: .3s;

}


.ImmigrationServices-container .ImmigrationServicesCard-container a:hover{
    cursor: pointer;
    color: #E1A92A;
}

.ImmigrationServices-container .ImmigrationServicesCard-container img{
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
}

.ImmigrationServices-container .ImmigrationServicesCard-container .btn-icon
{
    width: 12px;
    height: 12px;
    margin-bottom: 0;
    margin-left: 3px;
}

.ImmigrationServices-container .ImmigrationServicesCard-container .top-links
{
    display: flex;
    justify-content: start;
    gap: 15px;
}

.ImmigrationServices-container .ImmigrationServicesCard-container .top-links img{
    width: 15px;
}
.ImmigrationServices-container .ImmigrationServicesCard-container .top-links p{
    font-size: .8rem;

}