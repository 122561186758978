.why-choose-container
{

}

.why-choose-container .card-box
{
    width: 200px;
    height: 200px;
    border-radius: 25px;
    box-shadow: 0px 3px 18px rgba(0,0,0,0.1 );
    background-color: #FFFFFF;
    color: #373737;
    text-align: center;
    transition: .3s;
}

.why-choose-container .card-box:hover
{
    cursor: pointer;
    transform: translateY(-5px);
}

.why-choose-container .card-img
{
    width: 67.7px;
    height: 63.5px;
}

.why-choose-container .body-paragraph
{
    margin-top: 10px;
    font-size: 18px;
}