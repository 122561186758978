.ECardSection-container
{
}

.ECardSection-container .body-heading
{
    font-family: 'Poppins', sans-serif!important;
    font-size: 37px;
}



.ECardSection-container .bg-yellow
{
    background-color: #323232;
    border-radius: 12px;
    max-width: 1000px;
    background-image: url("../../../public/images/creatives/e-card-new-bg.png");
    /* background-image: url("../../../public/images/creatives/e-card-bg.webp"); */
    background-repeat: no-repeat;
    background-size: cover;
}

.ECardSection-container .social-icons-container li
{
    list-style: none;
}

.ECardSection-container .card-box
{
    background-color: transparent;
    padding: 10px 20px;
    cursor: pointer;
    transition: .3s;
}

.ECardSection-container .card-box:hover
{
    box-shadow: none;
    /* background-color: #d78a31; */
}

.ECardSection-container .card-anchor
{
    text-decoration: none;
}

.ECardSection-container .genral-btn
{
    background: white!important;
    color: #000000!important;
}
.ECardSection-container .genral-btn:hover
{
    background: #E9511A!important;
    color: white!important;
}

.ECardSection-container .main-img
{
    border-radius: 12px;
    /* width: 80%; */
}

.ECardSection-container .ecard-icon
{
    width: 75px;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 14px;
}

@media(max-width:992px)
{
    .ECardSection-container .bg-yellow
    {
        width: 90%;
    }
}