
p,h1,h2,h3,h4,h5,h6,a,button,img,small
{
    user-select: none;
}

.home-pag
{
    /* background-image: url("../public/images/creatives/home-page-bg.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
}


.elevate
{
    transform: translateY(-150px);
}

.left-gradient
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at 0% 50%, rgba(225, 53, 53, 0.157) 0%, #ffffff 25%);
    background-size:100% 100%;
}

@media(min-width:1200px)
{
    .left-gradient
    {
        background-repeat: no-repeat!important;
        background: radial-gradient(ellipse at 0% 50%, rgba(199, 19, 19, 0.109) 0%, #ffffff 60%);

        background-size:30% 100%;
    }
    
}

@media(max-width:992px)
{
    .left-gradient
    {
        background: radial-gradient(ellipse at 0% 60%, rgba(255, 255, 255, 0.157) 0%, #ffffff 60%);
        background-size:40% 100%;
    }
    .elevate
    {
        transform: translateY(0px);
    }
}
.left-gradient-upper
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at 5% 50%, rgba(255, 255, 255, 0.057) 0%, #00000000 25%);
    background-size:100% 100%;
}

@media(min-width:1200px)
{
    .left-gradient-upper
    {
        background-repeat: no-repeat!important;
        background: radial-gradient(ellipse at 0% 0%, rgba(255, 255, 255, 0.234) 0%, #00000008 60%);

        background-size:40% 100%;
    }

}

@media(max-width:992px)
{
    .left-gradient-upper
    {
        background: radial-gradient(ellipse at 0% 60%, rgba(113, 155, 199, 0.055) 0%, #ffffff00 60%);
        background-size:40% 100%;
    }
}

.right-gradient
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at 100% 50%, rgba(199, 19, 19, 0.157) 0%, #ffffff00 30%);

    background-size:100% 100%;
    background-position:right center;
}

@media(max-width:992px)
{
    .right-gradient
    {
        
        background-size:100%;
    }
}
.right-gradient-bottom
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at 100% 90%, rgba(199, 19, 19, 0.157) 0%, #ffffff00 30%);

    background-size:100% 100%;
    background-position:right center;
}

@media(max-width:992px)
{
    .right-gradient-bottom
    {
        
        background-size:100%;
    }
}
.right-upper-gradient
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at 100% 0%, rgba(199, 19, 19, 0.157) 0%, #ffffff00 40%);

    background-size:100% 100%;
    background-position:right center;
}

@media(max-width:992px)
{
    .right-upper-gradient
    {
        
        background-size:100%;
    }
}

.left-gradient-bottom
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at 0% 90%, rgba(199, 19, 19, 0.157) 10%, #ffffff00 40%);

    background-size:100% 100%;
    background-position:right center;
}

@media(max-width:992px)
{
    .left-gradient-bottom
    {
        
        background-size:100%;
    }
}


.bottom-gradient
{
    background-repeat: no-repeat!important;
    background: radial-gradient(circle at left bottom, rgba(253, 254, 255, 0.077) 0%, rgba(255, 255, 255, 0.062) 56%, rgba(255, 255, 255, 0) 93%, #ffffff00 100%);

    background-size:100% 100%;
    background-position:left bottom;
}

@media(max-width:992px)
{
    .bottom-gradient
    {
        
        background-size:100%;
    }
}



/* blog */

.blog-Catagory .main-catagory-container
{
    background:#1F2D4B;
    border-radius: 30px;
}
.blog-Catagory .catagory-container
{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    margin: 0!important;
}
.blog-Catagory .active
{
    background-color: #39B4E5;
}
.blog-Catagory .main-catagory-container .body-heading
{
    font-size: 15px!important;
    font-weight: normal;
    height: 100%;
}
    .blog-Catagory .main-catagory-container div:nth-child(1)
    {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }
    .blog-Catagory .main-catagory-container div:last-child
    {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
    }

    @media(max-width:1400px)
    {
        .blog-Catagory .main-catagory-container div:nth-child(4)
        {
            border-top-left-radius: 0;
            border-bottom-left-radius: 30px;
        }
        .blog-Catagory .main-catagory-container div:nth-child(3)
        {
            border-top-right-radius: 30px;
            border-bottom-right-radius: 0;
        }
        .blog-Catagory .main-catagory-container div:nth-child(1)
        {
            border-top-left-radius: 30px;
            border-bottom-left-radius: 0;
        }
        .blog-Catagory .main-catagory-container div:last-child
        {
            border-top-right-radius: 0;
            border-bottom-right-radius: 30px;
        }
    }
    @media(max-width:994px)
    {
        .blog-Catagory .main-catagory-container div:nth-child(4)
        {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .blog-Catagory .main-catagory-container div:nth-child(3)
        {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .blog-Catagory .main-catagory-container div:nth-child(1)
        {
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
        }
        .blog-Catagory .main-catagory-container div:last-child
        {
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
        }
    }