.blog-details-container
{
    color: #2D2D2D;
}

.blog-details-container .container
{
}

.blog-details-container .blog-thumbnail
{
    position: relative;
    top: -80px;
    color: #2D2D2D;
}

.blog-details-container .text-content .content-title
{
    color: #2D2D2D;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 1.8rem;
}
.blog-details-container .text-content .main-content-title
{
    font-weight: 600;
    font-size: 1.9rem;
}
.blog-details-container .text-content p
{
    line-height: 2rem;
}

.blog-details-container .social-icon-box{
    gap: 5px;
}

.blog-details-container .social-icon-box .social-icon{
    width: 40px;
    height: 40px;
    filter: grayscale(1);
    transition: .3s;
    cursor: pointer;
}
.blog-details-container .social-icon-box .social-icon:hover{
   
    filter: grayscale(0);
}


.blog-details-container .line-seprator
{
    width: 100%;
    border: 1px solid #CCCCCC;
    margin: auto;
}

.blog-details-container .popular-card 
{
    cursor: pointer;
}
.blog-details-container .popular-card .card-title
{
    color: #2D2D2D;
    font-size: 1rem;
    font-weight: 600;
}
.blog-details-container .popular-card .card-date
{
    color: #CCCCCC;
}
.blog-details-container .popular-card .popular-blog-img
{
    width: 40%;
    height: 100%;
    max-height: 150px;
    border-radius: 10px;
}

.blog-details-container .popular-blog-container
{
}

.blog-details-container .blog-info .blog-info-field p
{
    color:#373737;
    font-size: 16px;
    font-weight: 300;
}

.blog-details-container .text-content a{
    color: #E9511A!important;
}

.blog-details-container .card-box
{
    color: black;
    display: flex;
    padding: 20px;
    padding-bottom: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 21px #00000029;
    border-radius: 26px;
    height: 100%;
    cursor: pointer;
}


.blog-details-container .card-box .card-text-content
{
    width: 93%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin: auto;
}

.blog-details-container .card-box h5{
    color :#373737;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
}
.blog-details-container .card-box h2{
    color :#006EAE;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
}
.blog-details-container .card-box p{
    color :#949494;
    font-size: .78rem;
}


.blog-details-container .card-box a
{
    text-decoration: none;
    font-size: .8rem;
    transition: .3s;

}


.blog-details-container .card-box a:hover{
    cursor: pointer;
    color: #E1A92A;
}

.blog-details-container .card-box img{
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
}

.blog-details-container .card-box .btn-icon
{
    width: 12px;
    height: 12px;
    margin-bottom: 0;
    margin-left: 3px;
}

.blog-details-container .card-box .top-links
{
    display: flex;
    justify-content: start;
    gap: 15px;
}

.blog-details-container .card-box .top-links img{
    width: 15px;
}
.blog-details-container .card-box .top-links p{
    font-size: .8rem;

}